<template>
  <section
    class="d-flex flex-column wd-100p px-3 flex-wrap align-items-center px-4"
    v-if="safetyQuestions"
  >
    <div
      class="text-center text-muted-light strong-header-text font-weight-300 my-3"
    >
      {{ safetyQuestions[1].text }}
    </div>
    <video
      class="mx-auto my-5"
      style="
        width: fit-content;
        height: 300px;
        border: 1px solid white;
        border-radius: 0.3rem;
        max-width: 90%;
      "
      controls
      muted
      autoplay
      loop
      src="https://space7.s3.eu-west-1.amazonaws.com/videos/FloodFinal01.mp4"
    ></video>

    <v-radio-group
      v-model="safetyQuestions[1].answer_id"
      row
      class="max-content"
    >
      <v-radio
        v-for="option in safetyQuestions[1].answer_options"
        color="#fff"
        class="mx-4"
        dark
        :key="option.id"
        :value="option.id"
      >
        <template #label>
          <span class="ms-1 text-muted-light body-text">
            {{ option.text }}
          </span>
        </template>
      </v-radio>
    </v-radio-group>
  </section>
</template>

<script>
export default {
  name: "Security-1",
  data() {
    return {
      playerVars: {
        autoplay: 1,
      },
    };
  },
  computed: {
    safetyQuestions: function () {
      const room = this.$store.state.dashboard.rooms.find(
        (r) => r.name === "Project"
      );
      if (room)
        return room.systems
          .find((s) => s.category === "safety")
          .questions.filter((q) => q.journey_type === "dashboard");
      else return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.security-page {
  ::v-deep &__radio {
    width: 2em;
    height: 2em;
  }
}
</style>
